@media (max-width:630px) {
    .box_wrapper{
        margin-left:0 ;
        margin-right: 0;

    }
}
@media (max-width:470px) {
    .box_wrapper{
        width: 95%;
        margin: 0 auto;
    }

    .single_box{
        min-height: 362px;
        height: fit-content;
        padding-bottom: 30px;

    }
}

@media (max-width:1000px) {
    .whatToDo_content_container{
        padding-left: 30px;
        width: 90%;
    }
}

@media (max-width:500px) {
    .whatToDo_content_container{
        width: 95%;

    }

    .whatToDo_heading{
        font-size: 45px;
    }

    .whatToDo_para{
        font-size: 20px;
    }
}


.whatToDo_para{
    transform: translateY(20px);

}
.whatToDoBox:hover{
    background-color: #42BDAC;
}
.whatToDoBox:hover .whatToDo_para{
    animation: paraAnimate 0.2s linear ;
    animation-fill-mode: forwards;
}

@keyframes paraAnimate {
    0%{
     transform: translateY(20);
    }
    100%{

        transform: translateY(0);

    }
}

