@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;1,200;1,400&family=Work+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@1,300&family=Poppins:ital,wght@0,300;0,400;1,200;1,400&family=Work+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Noto+Sans:ital,wght@1,300&family=Poppins:ital,wght@0,300;0,400;1,200;1,400&family=Work+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400&family=Inter&family=Noto+Sans:ital,wght@1,300&family=Oxygen:wght@300&family=Poppins:ital,wght@0,300;0,400;1,200;1,400&family=Work+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400&family=Inter&family=Noto+Sans:ital,wght@1,300&family=Oxygen:wght@300&family=Poppins:ital,wght@0,300;0,400;1,200;1,400&family=Roboto&family=Work+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400&family=Inter&family=Noto+Sans:ital,wght@1,300&family=Oxygen:wght@300&family=Poppins:ital,wght@0,300;0,400;1,200;1,400&family=Roboto&family=Roboto+Slab&family=Work+Sans:wght@300;400&display=swap');

body {
  font-family: Poppins;
}

section{
  margin: 0;
}

body::-webkit-scrollbar{
  width: 12px;
  border-left: 1px solid rgb(107, 103, 103);
background-color:#18273e;
}
::-webkit-scrollbar-thumb {
  background: #42BDAC; 
  border-radius: 10px;
  /* height: 100px; */
}
.heroSectionWrapper{
  /* width: 100vw; */
  min-height: 100vh;
  height: fit-content;
  display: flex;
  padding-bottom: 100px;
  flex-direction: column;
   gap: 100px;
  position: relative;
  overflow-x: hidden;
  font-family: Poppins;
}
.heroSectionBgImg{
  display: block ;
  background-image: url("./assets/heroSectionImg.svg");
  position: absolute;
  z-index: -30;
  width: 100%;
  min-height: 100%;
  height: 100%;
}
.showDataAnimation{
  animation: dataVisible 1s ease-in-out 0 1 ;
 transition: all 1s;
}
.companyDrivenWrapper{
  font-family: 'Inter', sans-serif;
}
.salesTeamSection_wrapper{
  font-family: "Oxygen";
}


.idustryLineSection{
  animation: lineAnimation 1s linear 0s 1 ;
  animation-fill-mode: forwards;
}
.hiddenAnimation{
  animation: hiddenPara 1s linear 0s 1 ;
  animation-fill-mode: forwards;

}

@keyframes hiddenAnimation {
  0%{
   height: 100%;
  } 
  100%{
 height: 0;
  }
}
@keyframes lineAnimation {
  0%{
     height: 0;
  }
  100%{
    height: 170px;
  }
}

@keyframes getStartForm {
  0%{
  transform: translateX(0vw);
  }
  100%{
 transform: translateX(50vw);
  }
}
@keyframes dataVisible {
   0%{
        opacity: 0;

   } 
   100%{
 opacity: 1;
   }
}


/* these media queries for navbar */
@media (max-width:950px) {
  .navbar_wrapper{
    display: none;
  }

  .nav_menuIcon{
    display: block;
  }
}

@media (min-width:950px) {
 .nav_menu_bar{
  opacity: 0;
 }
}

@media (max-width:450px) {
  .nav_imgLogo{
    width: 150px;
  }
}

.nav_show_menu{
  animation: showMenuSlide 0.6s ease-in-out 0s 1 ;
  animation-fill-mode: forwards;
}
.nav_hide_menu{
  animation: hideMenuSlide 0.6s ease-in-out 0s 1 ;
  animation-fill-mode: forwards;
}

.addMenuFilter{
  filter: grayscale(8%) sepia(13%) blur(1px) brightness(55%) saturate(158%) contrast(134%);
  width: 100%;
  height: full;

}

@keyframes hideMenuSlide {
  0%{
   transform: translateX(0);
  }
  100%{
 transform: translateX(100vw);
  }
}

@keyframes showMenuSlide {
  0%{
     transform: translateX(100vw);
  }
  100%{
 transform: translateX(0vw);
  }
}