.animateMethodDesc {
  animation: animateDesc 1s linear;
  transition: all 1s;
}

@keyframes animateDesc {
  0% {
    height: fit-content;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1150px) {
  .single_secret {
    padding-right: 20px;
    padding-left: 20px;
  }

  .single_secret_left {
    width: 50%;
    padding-left: 20px;
  }

  .single_secret_right {
    width: 45%;
    margin-right: 20px;
  }
}

@media (max-width: 950px) {
  .secret_sec2_para {
    font-size: 20px;
  }
}

@media (max-width: 1000px) {
  .secretSec1 {
    margin-left: 20px;
    width: 90%;
  }
}

@media (max-width: 750px) {
  .secretSec1_para {
    font-size: 20px;
    font-weight: 400;
  }
  .single_secret_right {
    height: 300px;
  }
}
@media (max-width: 850px) {
  .single_secret_para {
    font-size: 20px;
  }

  .single_secret_title {
    font-size: 24px;
  }
}

@media (max-width:590px) {
  .method_plus{
    transform: translateX(50px);
  }
}

@media (max-width:700px) {
  .method_all_img_wrapper{
    width: 90%;
    gap: 70px;
  }


}

@media (max-width: 500px) {
  .method_all_img_wrapper {
    gap: 70px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .single_method_img {
    width: 100px;
  }
}

@media (max-width: 450px) {
  .secretSec1_heading {
    font-size: 40px;
    text-align: center;
  }

  .secretSec1_para {
    text-align: center;
  }

  .secretPage_container {
    gap: 100px;
  }

  .single_secret_num_title_container {
    gap: 10px;
  }
  .single_secret_title {
    font-size: 20px;
  }

  .just_img {
    width: 200px;
  }

  .method_main_day {
    font-size: 20px;
  }

  .method_main_title {
    transform: translateY(-48px) translateX(120px);
    font-size: 20px;
  }

  .method_plus{
    transform: translateX(10px);
  }

  .method_main_para{
    margin-top: -30px;
  }
}

@media (max-width: 650px) {
  .single_secret_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .single_secret_left {
    width: 90%;
  }
  .secret_sec2 {
    gap: 80px;
  }
  .secretPage_container {
    gap: 70px;
  }

  .single_secret_right {
    width: 80%;
  }
}
