@media (max-width:1050px) {
    .aboutGrowth_diff_container{
width: 95%;
    }
}

@media (max-width:800px) {
    .diffence_logo_container{
    }
    .growthLogo_img{
 width: 200px;
    }
    .other_agencies_text{
        font-size: 25px;
    }
}

@media (max-width:550px) {
  .single_diff_container{
    gap: 10px;
  }  

  .left_side_text{
    font-size: 20px;
    transform: translateY(160px);
}
.right_side_text{
    font-size: 20px;
    transform: translateY(160px);
  }



  .aboutgrowth_line{
    height: 200px;
  }
}

@media (max-width:500px) {
 .diffence_logo_container{
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
 } 

 .about_growth_wrapper{
    gap: 100px;
 }
 
 .growthLogo_img{
    width: 150px;
 }
 .other_agencies_text{
    font-size: 18px;
 }
}

