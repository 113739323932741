.nowarp-text{
    text-wrap: nowrap;
    white-space: nowrap;
}

@media (min-width: 850px) and (max-width: 1066px) {
    .main_text{
        font-size: 27px !important;
    }
    .subHeading_text{
        font-size: 16px !important;
    }
    .testimonial_text{
        font-size: 18px !important;

    }
}



@media (max-width:700px) {
    .sale_data_wrapper{
        gap: 40px;
        padding-right: 10px;
    }

    .processDeriven_heading{
        font-size: 28px;
    }

    .process_driven_sec1{
        gap: 20px;
    }

    .processDerivenPara{
        font-size: 18px;
        margin-top: 3px;
    }

    .processDriven_img{
        margin-top: 10px;
    }

    .industry_right{
        width: 95%;
        padding: 20px;
    }

    .sales_sec1_num{
        font-size: 40px;
    }

    .sale_sec1_title{
        font-size: 20px;
    }

    .sale_data_wrapper{
        gap: 60px;
    }
}

@media (max-width:600px) {
    .sale_data_wrapper{
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media (max-width:550px) {
    .salesTeam_sec1_para{
        font-size: 30px;
        
    }

    .processDeriven_heading{
        font-size: 25px;
    }
.lead_heading{
    font-size: 30px;
}

.lead_para{
    font-size: 18px;
}

}

@media (max-width:500px) {
    .home_sec1_para{
        font-size: 18px;
    }
}

@media (max-width:400px) {
    .home_sec1_para{
        font-size: 17px;
    }
}

@media (max-width:450px) {
    .salesTeam_sec1_para{
        font-size: 24px;
        font-weight: 600;
        
    }
}

@media (max-width:470px) {
    .sec1_num_title_container{
        gap: 20px;
    }
    .sales_sec1_num{
           font-size: 35px;
    }

    .sale_sec1_title{
        font-size: 18px;
    }

}

@media (max-width:380px) {
 

    .processDeriven_heading{
        font-size: 20px;
    }

    .processDerivenPara{
        font-size: 16px;
    }

    .process_lead_wrapper{
        margin-top: 20px;
    }
}

@media (max-width:850px) {
    .homePage_mainSec{
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-right: 10px;
        margin-left: 10px;

    }

    .homePage_mainLeft{
        width: 100%;
        text-align: center;
    }

    .homePage_Btn_container{
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .homePage_main_right{
        width: 100%;
    }
    .home_page_text_left{
    text-align: center;
    }
}

@media (max-width:900px) {
    .industry_left{
        width: 95%;
        /* margin-left: 20px; */
    }
}

@media (max-width:1300px) {
    .sales_data_wrapper{
        padding-left: 0px;
    }
}

@media (max-width:1230px) {
    .single_sales_data{
        width: 100%;
    }
}

@media (max-width:1050px) {
   .industry_container{
    display: flex;
    flex-direction: column;
    gap: 40px;
   } 

   .industry_right{
    margin: 0 auto;
   }

   .industry_left{
    margin: 0 auto;
   }

   .sales_sec1_num{
    font-size: 50px;
    
   }
}

@media (max-width:400px) {
    .indus_right_table{
        display: flex;
        flex-direction: column;
    }
    .industry_right{
        height: fit-content;
    }
}

@media (max-width:470px) {
    .indus_left_title{
        font-size: 18px;
    }

    .indus_left_para{
        font-size: 16px;
    }
}

.showLineAnimation {
    animation: lineAnimate 0.3s linear forwards;
    transform-origin: bottom;
}

.hideLineAnimation {
    animation: hideAnimate 0.3s linear forwards;
    transform-origin: bottom;
}
.showParaAnimation {
    animation: animatePara 0.3s linear forwards;
}

@keyframes animatePara {
    0% {
       height: 0px;
       opacity: 0;
        display: none;
    }
    100% {
       height: 150px; 
       opacity: 1;
       display: block;
       /* background-color: blueviolet; */
}
}

@keyframes hideAnimate {
    0% {
        height: 170px;
    }
    100% {
        height: 0px;
        background-color: #42BDAC;
    }
}

@keyframes lineAnimate {
    0% {
        height: 0;
    }
    100% {
        height: 170px;
        background-color: #42BDAC;
    }
}
