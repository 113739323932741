.swiper .res-slide {
    max-width: 360px;
    width: 100%;
    height: 510px;
    border-radius: 8px;
    z-index: 1;
}

.swiper {
    padding: 2rem;
}

.swiper-nav-btns button {
    width: 60px;
    height: 50px;
    border-radius: 50px;
    border: none;
    outline: none;
    background-color: hsl(210, 100%, 50%);
    color: #fff;
    margin: 0px 0.5rem;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    margin-top: 30px;
    transition: all 0.5s;
}

.swiper-nav-btns button:hover {
    background-color: hsl(210, 100%, 40%);
    cursor: pointer;
}

@media (max-width: 500px) {
    .first_img {
        margin-left: 0px;
    }
}

/* for first child */

.res-slide:nth-child(1)::before {
    content: "James Owens";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.09);
    transform: translateX(0px) translateY(100px);
    padding: 10px;
    padding-top: 20px;
    padding-left: 35px;
    font-family: Roboto-regular;
    color: white;
    font-size: 20px;
    z-index: 100;
    overflow: hidden;
    transition: all 0.5s ease;
}
.res-slide:nth-child(1)::after {
    content: "Sales Development Representative";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    font-family: Roboto-regular;
    transform: translateX(0px) translateY(80px);
    color: white;
    border-radius: 8px;
    transition: all 0.5s ease;
    padding: 10px;
    padding-left: 35px;
    z-index: 100;
}

/* for second child */

.res-slide:nth-child(2)::before {
    content: "Mathew Brown";
    position: absolute;
    bottom: 0;

    width: 100%;
    height: 100px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.09);
    transform: translateX(0px) translateY(100px);
    padding: 10px;
    padding-left: 35px;
    padding-top: 20px;
    font-family: Roboto-regular;
    color: white;
    font-size: 20px;
    z-index: 100;
    overflow: hidden;
    transition: all 0.5s ease;
}
.res-slide:nth-child(2)::after {
    content: "Sales Development Representative";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    color: white;
    border-radius: 8px;
    transition: all 0.5s ease;
    font-family: Roboto-regular;
    transform: translateX(0px) translateY(80px);
    padding: 10px;
    padding-left: 35px;
    z-index: 100;
}

/* for third child */

.res-slide:nth-child(3)::before {
    content: "Kevin Joeshi";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.09);
    transform: translateX(0px) translateY(100px);
    padding: 10px;
    padding-top: 20px;
    font-family: Roboto-regular;
    color: white;
    font-size: 20px;
    z-index: 100;
    overflow: hidden;
    transition: all 0.5s ease;
    padding-left: 35px;
}
.res-slide:nth-child(3)::after {
    content: "Lead Generation executive";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    color: white;
    border-radius: 8px;
    font-family: Roboto-regular;
    transform: translateX(0px) translateY(80px);
    transition: all 0.5s ease;
    padding: 10px;
    padding-left: 35px;
    z-index: 100;
}

/* for fourth child */

.res-slide:nth-child(4)::before {
    content: "Mark Lang";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.09);
    transform: translateX(0px) translateY(100px);
    padding: 10px;
    padding-top: 20px;
    font-family: Roboto-regular;
    color: white;
    font-size: 20px;
    z-index: 100;
    overflow: hidden;
    transition: all 0.5s ease;
    padding-left: 35px;
}
.res-slide:nth-child(4)::after {
    content: "Sales Development Representative";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    color: white;
    border-radius: 8px;
    font-family: Roboto-regular;
    transform: translateX(0px) translateY(80px);
    transition: all 0.5s ease;
    padding: 10px;
    padding-left: 35px;
    z-index: 100;
}

/* for five child */

.res-slide:nth-child(5)::before {
    content: "Pooja A";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.09);
    transform: translateX(0px) translateY(100px);
    padding: 10px;
    padding-top: 20px;
    font-family: Roboto-regular;
    color: white;
    font-size: 20px;
    z-index: 100;
    overflow: hidden;
    transition: all 0.5s ease;
    padding-left: 35px;
}
.res-slide:nth-child(5)::after {
    content: "Sales Development Representative";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    color: white;
    border-radius: 8px;
    font-family: Roboto-regular;
    transform: translateX(0px) translateY(80px);
    transition: all 0.5s ease;
    padding: 10px;
    padding-left: 35px;
    z-index: 100;
}

/* for six child */

.res-slide:nth-child(6)::before {
    content: "Divtya Jothibalan";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.09);
    transform: translateX(0px) translateY(100px);
    padding: 10px;
    padding-top: 20px;
    font-family: Roboto-regular;
    color: white;
    font-size: 20px;
    z-index: 100;
    overflow: hidden;
    transition: all 0.5s ease;
    padding-left: 35px;
}
.res-slide:nth-child(6)::after {
    content: "Market Researcher";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    color: white;
    border-radius: 8px;
    font-family: Roboto-regular;
    transform: translateX(0px) translateY(80px);
    transition: all 0.5s ease;
    padding: 10px;
    z-index: 100;
    padding-left: 35px;
}

/* for seven child */

.res-slide:nth-child(7)::before {
    content: "Swathisri V";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.09);
    transform: translateX(0px) translateY(100px);
    padding: 10px;
    padding-top: 20px;
    font-family: Roboto-regular;
    color: white;
    font-size: 20px;
    z-index: 100;
    overflow: hidden;
    transition: all 0.5s ease;
    padding-left: 35px;
}
.res-slide:nth-child(7)::after {
    content: "Market Resercher";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    color: white;
    border-radius: 8px;
    font-family: Roboto-regular;
    transform: translateX(0px) translateY(80px);
    transition: all 0.5s ease;
    padding: 10px;
    padding-left: 35px;
    z-index: 100;
}

/* for eigth child */

.res-slide:nth-child(8)::before {
    content: "Aparna Nair";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.09);
    transform: translateX(0px) translateY(100px);
    padding: 10px;
    padding-top: 20px;
    font-family: Roboto-regular;
    color: white;
    font-size: 20px;
    z-index: 100;
    overflow: hidden;
    transition: all 0.5s ease;
    padding-left: 35px;
}
.res-slide:nth-child(8)::after {
    content: "Lead Generation Manager";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    color: white;
    border-radius: 8px;
    font-family: Roboto-regular;
    transform: translateX(0px) translateY(80px);
    transition: all 0.5s ease;
    padding: 10px;
    z-index: 100;
    padding-left: 35px;
}

/* for 9 child */
.res-slide:nth-child(9)::before {
    content: "Naveen Kumar K";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.09);
    transform: translateX(0px) translateY(100px);
    padding: 10px;
    padding-top: 20px;
    font-family: Roboto-regular;
    color: white;
    font-size: 20px;
    z-index: 100;
    overflow: hidden;
    transition: all 0.5s ease;
    padding-left: 35px;
}
.res-slide:nth-child(9)::after {
    content: "Demand Generation Manager";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    color: white;
    border-radius: 8px;
    font-family: Roboto-regular;
    transform: translateX(0px) translateY(80px);
    transition: all 0.5s ease;
    padding: 10px;
    z-index: 100;
    padding-left: 35px;
}

/* for 10 child */

.res-slide:nth-child(10)::before {
    content: "Hafsah Abid";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.09);
    transform: translateX(0px) translateY(100px);
    padding: 10px;
    padding-top: 20px;
    font-family: Roboto-regular;
    color: white;
    font-size: 20px;
    z-index: 100;
    overflow: hidden;
    transition: all 0.5s ease;
    padding-left: 35px;
}
.res-slide:nth-child(10)::after {
    content: "Market Researcher";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    color: white;
    border-radius: 8px;
    font-family: Roboto-regular;
    transform: translateX(0px) translateY(80px);
    transition: all 0.5s ease;
    padding: 10px;
    z-index: 100;
    padding-left: 35px;
}

/* for 11 child */

.res-slide:nth-child(11)::before {
    content: "Vignesh";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.09);
    transform: translateX(0px) translateY(100px);
    padding: 10px;
    padding-top: 20px;
    font-family: Roboto-regular;
    color: white;
    font-size: 20px;
    z-index: 100;
    overflow: hidden;
    transition: all 0.5s ease;
    padding-left: 35px;
}
.res-slide:nth-child(11)::after {
    content: "Data researcher";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    color: white;
    border-radius: 8px;
    font-family: Roboto-regular;
    transform: translateX(0px) translateY(80px);
    transition: all 0.5s ease;
    padding: 10px;
    z-index: 100;
    padding-left: 35px;
}

/*  this is for putting the images  */
.res-slide:nth-child(1) {
    background: center / cover no-repeat url("../../assets/gm1.svg");
    transition: all 0.1s;
}

.res-slide:nth-child(2) {
    background: center / cover no-repeat url("../../assets/gm2.svg");
    transition: all 0.1s;
}

.res-slide:nth-child(3) {
    background: center / cover no-repeat url("../../assets/gm3.svg");
    transition: all 0.1s;
}

.res-slide:nth-child(4) {
    background: center / cover no-repeat url("../../assets/gm4.svg");
    transition: all 0.1s;
}

.res-slide:nth-child(5) {
    background: center / cover no-repeat url("../../assets/gm5.svg");
    transition: all 0.1s;
}
.res-slide:nth-child(6) {
    background: center / cover no-repeat url("../../assets/gm6.svg");
    transition: all 0.1s;
}
.res-slide:nth-child(7) {
    background: center / cover no-repeat url("../../assets/gm7.svg");
    transition: all 0.1s;
}
.res-slide:nth-child(8) {
    background: center / cover no-repeat url("../../assets/gm8.svg");
    transition: all 0.1s;
}
.res-slide:nth-child(9) {
    background: center / cover no-repeat url("../../assets/gm12.jpeg");
    transition: all 0.1s;
}
.res-slide:nth-child(10) {
    background: center / cover no-repeat url("../../assets/gm9.svg");
    transition: all 0.1s;
}
.res-slide:nth-child(11) {
    background: center / cover no-repeat url("../../assets/gm10.svg");
    transition: all 0.1s;
}
