

.musicWrapper{
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    /* background: linear-gradient(45deg , ); */
}

.loader{
    height: 70px;
    display: flex;
    align-items: center;
}
  
.loader .stroke{
    display: block;
    position: relative;
    background-color: #42BDAC;
    height: 100%;
    width: 5px;
    border-radius: 50px;
    margin: 0 4px;
    animation:  animate 1.2s linear infinite;

}

@keyframes animate {
    50%{
        height: 20%;
    }
    100%{
        height: 100%;
    }
}

.stroke:nth-child(1){
    animation-delay: 0s;
}
.stroke:nth-child(2){
    animation-delay: 0.3s;
}
.stroke:nth-child(3){
    animation-delay: 0.6s;
}
.stroke:nth-child(4){
    animation-delay: 0.6s;
}
.stroke:nth-child(5){
    animation-delay: 0.3s;
}
.stroke:nth-child(6){
    animation-delay: 0s;
}
.stroke:nth-child(7){
    animation-delay: 0.3s;
}
.stroke:nth-child(8){
    animation-delay: 0.6s;
}
.stroke:nth-child(9){
    animation-delay: 0.3s;
}
.stroke:nth-child(10){
    animation-delay: 0s;
}
.stroke:nth-child(11){
    animation-delay: 0.2s;
}
.stroke:nth-child(12){
    animation-delay: 0.4s;
}
.stroke:nth-child(13){
    animation-delay: 0.6s;
}
.stroke:nth-child(14){
    animation-delay: 0.3s;
}



.music_single_circle::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: inherit;
    position: absolute;
    top: 0;
    z-index: 10;
    left: 0;
    transition: background-color 1s;
}
  .music_single_circle:hover::before {
      animation: musicCircle 0.5s ;
      animation-fill-mode: forwards
    }
    
    .music_single_circle:hover{
        box-shadow: 1px 1px 10px #42BDAC;
    }
    
    
  @keyframes musicCircle {
    0%{
 transform: scale(0.1);
 box-Shadow:  1px 1px 10px  #42BDAC ;
 
}
    100%{
transform: scale(1);
background-color:#42BDAC ;
    }
  }
  
 @media (max-width:550px) {
    .musicSectioneheading{
        font-size: 23px;
    }

    .music_right_circle{
        width: 80px;
        height: 80px;
    }
 }
  
  
  
  
  

  
  
  
  
  
  