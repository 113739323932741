 @font-face {
     font-family: 'sebino-regular';   
       src: url('./fonts/Sebino-Regular.ttf') ;
    font-weight: normal;
    font-style: normal;
   }

   @font-face {
    font-family: 'Avram-semibold';
    src: url('./fonts/tour-de-force-avramsans-semibold.otf');
    font-weight: normal;
    font-style: normal;
   }

   @font-face {
    font-family: 'Avram-bold';
    src: url('./fonts/EM-Avram.Sans/AvramSans-Bold.otf');
    font-weight: normal;
    font-style: normal;
   }
   


   @font-face {
    font-family: 'Poppins-bold';
    src: url('./fonts/Poppins-1/Poppins-Bold.ttf');
    font-weight: normal;
    font-style: normal;
   }
   @font-face {
    font-family: 'Poppins-regular';
    src: url('./fonts/Poppins-1/Poppins-Regular.ttf');
    font-weight: normal;
    font-style: normal;
   }
   @font-face {
    font-family: 'Poppins-medium';
    src: url('./fonts/Poppins-1/Poppins-Medium.ttf');
    font-weight: normal;
    font-style: normal;
   }
   @font-face {
    font-family: 'Poppins-semibold';
    src: url('./fonts/Poppins-1/Poppins-SemiBold.ttf');
    font-weight: normal;
    font-style: normal;
   }
  
  @font-face {
      font-family: 'Roboto-black';
      src: url('./fonts/Roboto-font/Roboto-Black.ttf') ;
      font-weight: normal;
      font-style: normal;
    }
  @font-face {
      font-family: 'Roboto-regular';
      src: url('./fonts/Roboto-font/Roboto-Regular.ttf') ;
      font-weight: normal;
      font-style: normal;
    }

    @font-face {
      font-family: 'Roboto-bold';
      src: url('./fonts/Roboto-font/Roboto-Bold.ttf');
      font-weight: normal;
      font-style: normal;
    
    }
    @font-face {
      font-family: 'Roboto-boldItalic';
      src: url('./fonts/Roboto-font/Roboto-BoldItalic.ttf');
      font-weight: normal;
      font-style: normal;
    
    }
   

    @font-face {
      font-family: 'Roboto-blackItalic';
      src: url('./fonts/Roboto-font/Roboto-BlackItalic.ttf');
    }
    



