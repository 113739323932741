
.menu_filter_left{
    animation: filterAnimate 0.8s linear ;
    animation-fill-mode: forwards;
   
}

@keyframes filterAnimate {
    0% {
        display: none;
    }

    100% {
        display: block;
        background-color: #1C2635;
        opacity: 70%;
        
    }
}

@media (max-width:1100px) {
    .aboutCeo_wrapper{
     padding-left: 20px;
     padding-right: 20px;
    }
}
@media (max-width:1000px) {
    .aboutCeo_para{
        font-size: 20px;
    }
    .aboutCeo_subpara{
        font-size: 20px;
    }
    .aboutCeo_name{
        font-size: 16px;
    }

    .footer_main_wrapper{
        display: flex;
        flex-direction: column;
        align-items: normal;
        gap: 30px;
    }

    .footer_main_end{
        width: fit-content;
        gap: 20px;
    }

    .footer_left_midd_wrapper{
        justify-content: space-between;
        width: 75%;
        /* background: red; */
    }

    .linkedInImg{
        display: flex;
        justify-content: flex-start;
    }

    .footer_menu_text{
        font-size: 18px;
    }

    .footer_menu_items{
        font-size: 16px;
    }

    .footer_left_title{
        font-size: 16px;
    }
.footer_left_img{
    width: 22px;
}

}

@media (max-width:800px) {
    .footer_left_midd_wrapper{
        width: 90%;
    }
}

@media (max-width:650px) {
    .aboutCeo_wrapper{
        display: flex;
        flex-direction: column;
    }
    .company_driven_heading{
        font-size: 24px;
    }
    .companyDrivenWrapper{
        padding: 20px;
    }

    .company_logo_container{
        gap: 50px;
        
    }
}

@media (max-width:630px) {
    .footer_left_midd_wrapper{
        display: flex;
        flex-direction: column;
        /* background-color: red; */
        align-items: normal;
        /* gap: 10px; */
        margin-top: 30px;
    }
    .footer_menu_text{
        margin-top: -30px;
    }
    .footer_main_end{
        margin-top: -80px;
    }
}

@media (max-width:500px) {
    .single_company_img{
        width: 130px;
        height: 40px;
    }
}

@media (max-width:400px) {
    .footer_end_left{
        width: 100%;
        font-size: 20px;
    }

    .footer_end_right{
        width: 100px;
    }
}

