
@media (max-width:850px) {
    .about_sec3_img{
        width: 300px;
        height: 300px;
    }
    .about_sec3_para{
        font-size: 26px;
    }
}

@media (max-width:700px) {
    
   .aboutUs_text{
    font-size: 40px;
   }

   .aboutUs_section1{
    gap: 25px;
   }

   .aboutUs_para{
    font-size: 20px;
   }

   .about_section2_para{
    font-size: 20px;
   }

   .about_dotImg{
    margin-top: 10px;
    /* z-index:; */
   }
   .about_sec3_para{
    font-size: 22px;
   }
   .about_sec3{
    align-items: center;
   }
}

@media (max-width:450px) {
    .about_dotImg{
        display: none;
    }
.about_sec4_heading{
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
}

.about_sec4{
    margin-top: 100px;
}

}

@media (max-width:550px) {
    .aboutUs_section1{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }

    .aboutUs_section1_left{
        width: 90%;
        margin-top: 10px;
        gap: 2px;
    }

    .about_sec3{
        display: flex;
        flex-direction: column;
        margin-top: 100px;
    }
    .about_sec3_para{
        width: 90%;
    }
}