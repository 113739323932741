


.form_title::placeholder{
    color: white;
}

@media (max-width:1300px) {
    
    .pitchMe_wrapper{
     padding-left: 40px;
     padding-right: 40px;       
    }
}

@media (max-width:1250px) {
    .pitchMe_left_heading{
        font-size: 40px;
    }

    .pitchMe_para{
        font-size: 20px;
    }
}

@media (max-width:1150px) {
    .pitchMe_wrapper{
        display: flex;
        flex-direction: column;
    }

    .form_wrapper{
        margin: 0 auto;
    }

    .pitchMe_left_container{
        width: 100%;
        text-align: center;
    }

    .pitchMe_right_container{
        margin: 0 auto;
        /* transform: translateX(100vw); */
    }
    .unique_card_container{
        width: 95%;
        height: fit-content;
    }
}
@media (max-width:720px) {
    .pitchMe_right_container{
        width: 100%;
        margin: 0 auto;
        padding: 20px;
        height: 700px;
        
    }

    .bookNow_wrapper{
        padding: 20px;
    }

    .form_wrapper{
        width: 100%;
        margin: 0 auto;
    
    }

    .single_form_input{
        width: 100%;
    }

}

@media (max-width:630px) {
    .pitchMe_left_container{
        gap: 14px;
    }
    .pitchMe_left_heading{
        font-size:26px ;
    }
    .pitchMe_para{
        font-size: 17px;
    }

    .getStartHeading{
        font-size: 24px;
    }

    .getStartSubHeading{
        font-size: 19px;
    }

    .method_sec1_heading{
        padding-left: 20px;
    }

    .unique_heading{
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;

    }
}

@media (max-width:600px) {
    .pitchMe_right_container{
        height: 620px;
    }

    .pitchMe_right_container{
        gap: 25px;
    }
}
@media (max-width:515px) {
    .single_form_input{
        gap: 10px;
    }

    .pitchMe_wrapper{
        padding-left: 10px;
        padding-right: 10px;
    }

    .method_sec2_heading{
        font-size: 26px;
    }

    .method_Sec2_img{
        width: 100%;
        text-align: center;
        margin: 0 auto;
    }

    .unique_heading{
        font-size: 28px;
    }
}

@media (max-width:450px) {
    .form_title{
        font-size: 12px;
        text-align: left;
    }

    .method_sec1_heading{
        font-size: 45px;
    }
}

@media (max-width:850px) {
    .methodsec2_container{
        width: 100%;
        display: flex;
        flex-direction: column;
        
    }

    .unique_card_subHeading{
        font-size: 30px;
    }
}

@media (max-width:1000px) {
    .method_main_wrapper{
        width: 95%;
    }

    .method_main_day{
        font-size: 26px;
        width: 170px;
        /* background-color: red; */
    }

    .method_main_title{
        font-size: 26px;
    }

    .method_main_para{
        font-size: 18px;
        padding-right: 2px;
    }
}

@media (max-width:470px) {
    .method_main_top{
        gap: 15px;
    }
}

@media (max-width:450px) {
    .method_main_top{
        display: flex;
        flex-direction: column;
        /* text-align: center; */
    }

    .method_main_right{
        width: 100%;
    }
    .method_main_day{
        text-align: left;
    }

    .just_img{
        width: 90%;
    }
}

@media (max-width:380px) {
    .unique_heading{
        font-size: 20px;
    }

    .unique_card_subHeading{
        font-size: 20px;
    }

    .getStartHeading{
        font-size: 20px;
    }
}

.getStart{
    animation: getStartAnimation 0.5s linear 0s 1 ;
    animation-fill-mode: forwards;
  }
  @keyframes getStartAnimation {
    0%{ 
      transform: translateX(0);
  
    }
    100%{
   transform: translateX(-100vw);
    }
  }

  
.bookNow{
    animation: bookNowAnimation 0.5s linear 0s 1 ;
    animation-fill-mode: forwards;
  }

  @keyframes bookNowAnimation {
    0%{
   transform: translateX(80vw);
    }
    100%{
   transform: translateX(0vw);
    }
  }
  

